<template>
  <div class="SignInComponent">
    <v-card max-width="500px" class="mx-auto">
      <v-card-title primary-title> サインイン </v-card-title>
      <v-form ref="signInForm">
        <v-card-text>
          <v-text-field
            name="userName"
            label="ユーザー名"
            id="userName"
            v-model="form.userName"
            :rules="[inputRules.required]"
            required
          ></v-text-field>

          <v-text-field
            name="password"
            label="パスワード"
            id="password"
            v-model="form.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            :rules="[
              inputRules.required,
              inputRules.minLength8,
              inputRules.letterType,
            ]"
            required
          ></v-text-field>
          <v-card-text class="pa-0 text-caption">
            パスワードに使える文字種は半角英数字と、以下に示す半角記号です<br />
            &nbsp;^$*.[]{}()?\"!@#%&amp;/\,&gt;&lt;':;|_~`=+-
          </v-card-text>

          <v-card-text class="px-0 pb-0 text-caption">
            <a @click="openPasswordResetModal">パスワードを忘れた場合</a>
          </v-card-text>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="signIn"
            :disabled="!isValid"
            class="mx-auto"
            color="info"
          >
            <span v-if="!isLoading">サインイン</span>
            <v-progress-circular v-else indeterminate size="32" />
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <!-- TODO： コンポーネント化 -->
    <v-dialog
      v-model="isOpenModal"
      scrollable
      :overlay="true"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title primary-title>
          パスワードリセット申請
          <v-spacer></v-spacer>
          <v-icon @click="closePasswordResetModal">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          登録されたメールアドレスに認証コードが届きます。<br />
          パスワードリセット実行画面に「ユーザー名」「認証コード」「新パスワード」を入力してください。
        </v-card-text>
        <v-form ref="passwordResetForm">
          <v-card-text>
            <v-text-field
              name="userName"
              label="ユーザー名"
              id="userName"
              v-model="form.userName"
              required
              :rules="[inputRules.required]"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="passwordResetRequest" class="mx-auto" color="info">
              <span v-if="!isLoading">申請</span>
              <v-progress-circular v-else indeterminate size="32" />
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import cognito from "@/cognito";

export default {
  name: "SignInComponent",
  data: () => ({
    form: {
      userName: "",
      password: "",
    },
    showPassword: false,
    inputRules: {
      required: (v) => !!v || "入力必須の項目です",
      minLength8: (v) => v.length >= 8 || "8文字以上で入力してください",
      letterType: (v) =>
        cognito.passwordReg.test(v) ||
        "パスワードに使えない文字が含まれています",
    },
    isOpenModal: false,
    isValid: false,
    isLoading: false,
  }),
  computed: {
    signUpPagePath: () => {
      const basePath = `/SignUp?userName=${this.form.userName}`;
      const redirectPath = this.$route.query.redirect;
      if (!redirectPath) {
        return basePath;
      }
      return `${basePath}&redirect=${redirectPath}`;
    },
  },
  watch: {
    form: {
      handler() {
        this.isValid = this.$refs.signInForm.validate();
      },
      deep: true,
    },
  },
  methods: {
    async signIn() {
      if (!this.$refs.signInForm.validate()) {
        return;
      }
      this.isLoading = true;
      try {
        const challengeName = await cognito.signIn(
          this.form.userName,
          this.form.password
        );

        // パスワード更新が必要な場合、画面遷移
        if (challengeName === "NEW_PASSWORD_REQUIRED") {
          this.$router.push(this.signUpPagePath);
          this.isLoading = false;
          return;
        }

        // サインイン成功した場合
        this.redirect();
      } catch (error) {
        console.error(error);
        const messages = ["サインインに失敗しました。"];
        switch (error.name) {
          case "NotAuthorizedException":
            messages.push(
              "ユーザー名・パスワードに間違いがないか確認してください。"
            );
            break;
          default:
            messages.push(
              "時間を空けて再度実行していただくか、管理者までご連絡ください。",
              `エラーコード: ${error.name}`
            );
        }
        alert(messages.join("\n"));
      }
      this.isLoading = false;
    },
    redirect() {
      const redirectPath = this.$route.query.redirect ?? "/";
      this.$router.replace(redirectPath);
    },
    openPasswordResetModal() {
      this.isOpenModal = true;
    },
    closePasswordResetModal() {
      this.isOpenModal = false;
    },
    async passwordResetRequest() {
      if (!this.$refs.passwordResetForm.validate()) {
        return;
      }

      this.isLoading = true;
      try {
        await cognito.passwordResetRequest(this.form.userName);
        alert("パスワードリセット画面に遷移します。");
        this.$router.push("/PasswordReset");
      } catch (error) {
        // TODO: エラーコードに合わせたメッセージ表示
        alert(
          "パスワードリセット申請に失敗しました\nユーザー名に間違いがないか確認してください。"
        );
        console.error(error);
      }
      this.isLoading = false;
    },
  },

  // ライフサイクルフック
  beforeMount() {
    document.title = "サインイン | 安否確認システム";
  },
};
</script>
