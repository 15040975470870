import cognito from "@/cognito";
// --------------------------------
// NOTE
// 環境変数VUE_APP_STAGEが未定義の場合は開発環境として接続
// 基本的にはプロジェクト直下の.envまたは.env.localファイルでVUE_APP_STAGEを定義すること
// amplifyでのビルド後にAmplifyコンソールから設定した環境変数を.envファイルに書き込み、
// デプロイ先に配置され、アプリケーション起動時に読み込まれる
// 環境変数の.envファイルへの書き込みはamplify.ymlに記述
// --------------------------------

const stage = process.env.VUE_APP_STAGE ?? "develop";
export default {
  Auth: {
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "protfupe",
        endpoint: `${process.env.VUE_APP_API_ENDPOINT}/${stage}`,
        custom_header: async () => {
          return { Authorization: `Bearer ${await cognito.getJwtToken()}` };
        },
      },
    ],
  },
};
