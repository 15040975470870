import Vue from "vue";
import Vuex from "vuex";

import safetyAnswerInput from "./modules/safetyAnswerInput";
import healthAnswerInput from "./modules/healthAnswerInput";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cellphoneNumber: "",
  },
  // stateを直接触らせず、getters経由で取得させる方が安全な実装
  getters: {
    cellphoneNumber: (state) => state.cellphoneNumber,
  },
  mutations: {
    //   NOTE 電話番号を個別で使う可能性があるため個別でも取り扱えるように
    setCellphoneNumber: (state, cellphoneNumber) => {
      state.cellphoneNumber = cellphoneNumber;
    },
    resetCellphoneNumber: (state) => {
      state.cellphoneNumber = "";
    },
  },
  actions: {},
  modules: {
    safetyAnswerInput,
    healthAnswerInput,
  },
});
