import { API } from "aws-amplify";
const apiName = "protfupe";

async function getHealthReports() {
  const result = {
    hasError: false,
    message: "",
    items: [],
  };
  const limit = 10;
  const resourcePath = `/health_reports?limit=${limit}`;
  const myInit = {
    response: true,
  };

  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = true;
    return result;
  }

  result.items = apiResponse.data.items;
  return result;
}

// NOTE: 新規登録・更新ともにAPIは共通、修正フラグの値はAPI内部で判定
async function postHealthReport(corporateNumber, escapedDate, params) {
  const result = {
    hasError: false,
    message: "",
  };

  const resourcePath = `/health_reports/${corporateNumber}/${escapedDate}/`;
  const myInit = {
    response: true,
    body: params,
  };

  const apiResponse = await API.post(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = true;
    result.message = "登録エラーがありました。";
    return result;
  }

  return result;
}

export { getHealthReports, postHealthReport };
