import { API } from "aws-amplify";
const apiName = "protfupe";

async function getSafetyConfirmations() {
  const result = {
    hasError: false,
    message: "",
    items: [],
  };

  const resourcePath = `/safety_confirmations/`;
  const myInit = {
    response: true,
  };

  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = true;
    return result;
  }

  result.items = apiResponse.data.items;
  return result;
}

// NOTE: 回答ページは認証不要にするため、法人番号やイベント名をパスパラメータで渡す
// params = {
//   cellphoneNumber: String,
//   yourStatus: String,
//   familyStatus: String,
//   currentWhereabouts: String,
//   availableGoworkTime: String,
//   availableTransportation: String,
//   houseStatus: String,
//   gpsInformation: Object,
//   freeEntryfield: String,
//   answeredDate: "YYYY/MM/DD hh:mm:ss",
// };
async function postSafetyConfirmation(corporateNumber, eventName, params) {
  const result = {
    hasError: false,
    message: "",
  };
  const resourcePath = `/safety_answer/${corporateNumber}/${eventName}/`;
  const myInit = {
    response: true,
    body: params,
  };

  const apiResponse = await API.post(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );

  if (apiResponse.error) {
    result.hasError = true;
    result.message = "登録エラーがありました";
    return result;
  }

  return result;
}

export { getSafetyConfirmations, postSafetyConfirmation };
