<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h1>{{ $route.params.eventName }}:安否回答</h1>
        </v-col>
      </v-row>
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <h2>電話番号を入力してください</h2>
            <v-text-field
              v-model="cellphoneNumber"
              :rules="[inputRules.required, inputRules.numberOnly]"
              label="電話番号入力欄"
              placeholder="00011112222"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h2>該当項目にチェックしてください。</h2>
            <v-radio-group v-model="yourStatusSelected" mandatory>
              <p>1.本人の状況</p>
              <v-radio
                v-for="(item, index) in yourStatuses"
                :key="index"
                :label="item"
                :value="item"
              ></v-radio>
            </v-radio-group>
            <v-radio-group v-model="familyStatusSelected" mandatory>
              <p>2.家族の状況</p>
              <v-radio
                v-for="(item, index) in familyStatuses"
                :key="index"
                :label="item"
                :value="item"
              ></v-radio>
            </v-radio-group>
            <v-radio-group v-model="currentWhereaboutsSelected" mandatory>
              <p>3.現在の居場所</p>
              <v-radio
                v-for="(item, index) in currentWhereaboutses"
                :key="index"
                :label="item"
                :value="item"
              ></v-radio>
            </v-radio-group>
            <v-radio-group v-model="availableGoworkTimeSelected" mandatory>
              <p>4.出社可能時間</p>
              <v-radio
                v-for="(item, index) in availableGoworkTimes"
                :key="index"
                :label="item"
                :value="item"
              ></v-radio>
            </v-radio-group>
            <v-radio-group v-model="availableTransportationSelected" mandatory>
              <p>5.出社可能な場合の交通手段</p>
              <v-radio
                v-for="(item, index) in availableTransportations"
                :key="index"
                :label="item"
                :value="item"
              ></v-radio>
            </v-radio-group>
            <v-radio-group v-model="houseStatusSelected" mandatory>
              <p>6.自宅の状況</p>
              <v-radio
                v-for="(item, index) in houseStatuses"
                :key="index"
                :label="item"
                :value="item"
              ></v-radio>
            </v-radio-group>
            <v-radio-group v-model="gpsInformationSelected" mandatory>
              <p>7.GPS情報の報告</p>
              <v-radio
                v-for="(item, index) in gpsInformations"
                :key="index"
                :label="item.text"
                :value="item.value"
              ></v-radio>
            </v-radio-group>
            <p v-if="gpsInformationSelected">
              現在地の緯度経度: {{ this.latitude }}, {{ this.longitude }}
            </p>
            <p>8.自由記入欄</p>
            <v-text-field
              v-model="freeEntryfield"
              label="自由記入欄"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-btn color="primary" class="ml-auto" @click="postSelectedItems">
            回答
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { postSafetyConfirmation } from "../apis/safetyConfirmations";
import { formatDatetime } from "../utils/format";

export default {
  name: "SafetyAnswer",
  data: () => ({
    cellphoneNumber: "",
    inputRules: {
      required: (v) => !!v || "入力必須の項目です",
      numberOnly: (v) => /^[0-9]+$/.test(v) || "半角数字のみで入力してください",
    },
    yourStatuses: ["無事", "軽症", "重症"],
    yourStatusSelected: "",
    familyStatuses: ["無事", "軽症", "重症", "未確認"],
    familyStatusSelected: "",
    currentWhereaboutses: ["自宅", "社内", "移動中", "その他"],
    currentWhereaboutsSelected: "",
    availableGoworkTimes: [
      "30分以内",
      "1時間以内",
      "2時間以内",
      "2時間以上",
      "本日は出社不可",
    ],
    availableGoworkTimeSelected: "",
    availableTransportations: ["電車", "バス", "車", "自転車", "徒歩"],
    availableTransportationSelected: "",
    houseStatuses: ["住める", "住めない", "未確認"],
    houseStatusSelected: "",
    gpsInformations: [
      { text: "する", value: true },
      { text: "しない", value: false },
    ],
    gpsInformationSelected: "",
    freeEntryfield: "",
    latitude: "",
    longitude: "",
  }),
  watch: {
    gpsInformationSelected: function (newValue) {
      if (newValue === true) {
        navigator.geolocation.getCurrentPosition(this.getLatLong);
      } else {
        this.latitude = "";
        this.longitude = "";
      }
    },
  },
  computed: {
    ...mapGetters({
      storedCellphoneNumber: "cellphoneNumber",
      storedYourStatus: "safetyAnswerInput/yourStatus",
      storedFamilyStatus: "safetyAnswerInput/familyStatus",
      storedCurrentWhereabouts: "safetyAnswerInput/currentWhereabouts",
      storedAvailableGoworkTime: "safetyAnswerInput/availableGoworkTime",
      storedAvailableTransportation:
        "safetyAnswerInput/availableTransportation",
      storedHouseStatus: "safetyAnswerInput/houseStatus",
      storedFreeEntryfield: "safetyAnswerInput/freeEntryfield",
    }),
    gpsInformation() {
      return {
        latitude: this.latitude,
        longitude: this.longitude,
      };
    },
  },
  methods: {
    ...mapMutations({
      resetStoredSafetyAnswerInput: "safetyAnswerInput/reset",
    }),
    // 選択された項目を回答更新APIにPOSTリクエスト処理
    async postSelectedItems() {
      if (!this.$refs.form.validate()) {
        alert("入力内容にエラーがあります。");
        return;
      }

      const corporateNumber = this.$route.params.corporateNumber;
      const eventName = this.$route.params.eventName;
      const params = {
        cellphoneNumber: this.cellphoneNumber,
        yourStatus: this.yourStatusSelected,
        familyStatus: this.familyStatusSelected,
        currentWhereabouts: this.currentWhereaboutsSelected,
        availableGoworkTime: this.availableGoworkTimeSelected,
        availableTransportation: this.availableTransportationSelected,
        houseStatus: this.houseStatusSelected,
        gpsInformation: this.gpsInformation,
        freeEntryfield: this.freeEntryfield,
        answeredDate: formatDatetime(new Date()),
      };

      const result = await postSafetyConfirmation(
        corporateNumber,
        eventName,
        params
      );

      if (result.hasError) {
        alert(result.message);
        return;
      }

      alert("回答の送信に成功しました。\nTOPページに遷移します。");
      // 画面遷移
      this.resetStoredSafetyAnswerInput();
      this.$router.push("/");
      return;
    },
    getLatLong(position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
    },
  },
  // タイトルの設定
  beforeMount() {
    document.title = "安否回答 | 安否確認システム ";
  },
  created() {
    this.cellphoneNumber = this.storedCellphoneNumber;
    // ラジオボタンの初期値設定
    this.yourStatusSelected = this.storedYourStatus;
    this.familyStatusSelected = this.storedFamilyStatus;
    this.currentWhereaboutsSelected = this.storedCurrentWhereabouts;
    this.availableGoworkTimeSelected = this.storedAvailableGoworkTime;
    this.availableTransportationSelected = this.storedAvailableTransportation;
    this.houseStatusSelected = this.storedHouseStatus;
    this.freeEntryfield = this.storedFreeEntryfield;
    // NOTE GPSに関してはstoreを使わない
    this.gpsInformationSelected = this.gpsInformations[1].value;
  },
};
</script>
