<template>
  <v-app>
    <v-app-bar app clipped-left color="white" v-if="!isNoHeaderPage">
      <v-app-bar-nav-icon
        v-if="isRequireAuthPage"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        {{ headerCompanyName }} 安否確認システム
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="signOut" v-if="isRequireAuthPage" color="error">
        <span v-if="!isLoading">サインアウト</span>
        <v-progress-circular v-else indeterminate size="32" />
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-navigation-drawer
        v-if="isRequireAuthPage"
        app
        clipped
        v-model="drawer"
      >
        <v-list dense nav>
          <v-list-item
            v-for="item in navigationItems"
            :key="item.displayName"
            :to="item.path"
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.displayName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import cognito from "@/cognito";

export default {
  name: "App",

  data: () => ({
    navigationItems: [
      { path: "/", displayName: "TOP" },
      // { path: "", displayName: "" },
    ],
    drawer: false,
    isLoading: false,
  }),
  computed: {
    isNoHeaderPage() {
      return this.$route.meta.header === "none";
    },
    isRequireAuthPage() {
      const authPages = ["/SignIn", "/SignUp", "/PasswordReset"];
      return !authPages.includes(this.$route.path);
    },
    headerCompanyName() {
      return this.isRequireAuthPage ? this.companyName : "";
    },
  },
  methods: {
    signOut() {
      this.isLoading = true;
      alert("サインアウトします");
      // this.$store.commit("resetCompany");
      cognito
        .signOut()
        .catch((e) => {
          console.error(e);
          alert("サインアウトに失敗しました");
        })
        .finally(() => {
          this.isLoading = false;
          this.$router.push("/SignIn");
        });
    },
  },
};
</script>
