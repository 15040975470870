<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h1>
            体温報告
            <span v-if="storedIsModify">({{ storedRegistDate }}分修正)</span>
          </h1>
        </v-col>
      </v-row>
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <h2>電話番号を入力してください。</h2>
            <v-text-field
              v-model="cellphoneNumber"
              :rules="[inputRules.required, inputRules.numberOnly]"
              label="電話番号入力欄"
              placeholder="00011112222"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h2>下記項目を入力してください。</h2>
            <p>1.体温</p>
            <v-select
              :items="bodyTemperatures"
              v-model="bodyTemperature"
              :rules="[inputRules.required]"
              label="体温"
            ></v-select>
            <p>2.自由記入欄</p>
            <v-text-field
              v-model="freeEntryField"
              label="自由記入欄"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-btn color="primary" class="ml-auto" @click="putHealthReport">
            回答
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { postHealthReport } from "../apis/healthReports";
import { formatDate, formatDatetime } from "../utils/format";

export default {
  name: "HealthAnswer",
  data: () => ({
    cellphoneNumber: "",
    inputRules: {
      required: (v) => !!v || "入力必須の項目です",
      numberOnly: (v) => /^\d+$/.test(v) || "半角数字のみで入力してください",
    },
    bodyTemperatures: [],
    bodyTemperature: "",
    freeEntryField: "",
  }),
  computed: {
    ...mapGetters({
      storedCellphoneNumber: "cellphoneNumber",
      // { 呼び出しに使うgetter名: "module名/moduleのgetter名" },
      storedBodyTemperature: "healthAnswerInput/bodyTemperature",
      storedFreeEntryField: "healthAnswerInput/freeEntryField",
      storedRegistDate: "healthAnswerInput/registDate",
      storedIsModify: "healthAnswerInput/isModify",
    }),
  },
  methods: {
    ...mapMutations(
      // { 呼び出しに使うmutation名: "module名/moduleのmutation名" },
      { resetStoredHealthAnswerInput: "healthAnswerInput/reset" }
    ),
    // 選択された項目を回答更新APIにPOSTリクエスト処理
    async putHealthReport() {
      if (!this.$refs.form.validate()) {
        alert("入力内容にエラーがあります。");
        return;
      }

      const corporateNumber = this.$route.params.corporateNumber;
      const currentDate = new Date();
      const escapedDate = (
        this.storedIsModify ? this.storedRegistDate : formatDate(currentDate)
      ).replace(/\//g, "_");
      const lastUpdateDate = formatDatetime(currentDate);
      const params = {
        cellphoneNumber: this.cellphoneNumber,
        bodyTemperature: this.bodyTemperature,
        freeEntryField: this.freeEntryField,
        lastUpdateDate,
      };

      const result = await postHealthReport(
        corporateNumber,
        escapedDate,
        params
      );

      if (result.hasError) {
        alert(result.message);
        return;
      }

      alert("体温報告の送信に成功しました。\nTOPページに遷移します。");
      // 画面遷移
      this.resetStoredHealthAnswerInput();
      this.$router.push("/");
      return;
    },
  },
  // タイトルの設定
  beforeMount() {
    document.title = "体温報告 | 安否確認システム ";
  },
  created() {
    // NOTE: 35.0~42.0を0.1刻み
    this.bodyTemperatures = [...Array(420 - 350 + 1)].map((_, i) => {
      const str = String(350 + i);
      return str.slice(0, 2).concat(".").concat(str.slice(2));
    });

    this.cellphoneNumber = this.storedCellphoneNumber;
    this.bodyTemperature = this.storedBodyTemperature;
    this.freeEntryField = this.storedFreeEntryField;
  },
};
</script>
