export default {
  namespaced: true,
  state: {
    bodyTemperature: "",
    freeEntryField: "",
    registDate: "",
    isModify: false,
  },
  getters: {
    bodyTemperature: (state) => state.bodyTemperature,
    freeEntryField: (state) => state.freeEntryField,
    registDate: (state) => state.registDate,
    isModify: (state) => state.isModify,
  },
  mutations: {
    set: (state, answerItem) => {
      state.bodyTemperature = answerItem.bodyTemperature;
      state.freeEntryField = answerItem.freeEntryField;
      state.registDate = answerItem.registDate;
      // NOTE: storeに値を格納する => 回答の編集を行う
      state.isModify = true;
    },
    reset: (state) => {
      state.bodyTemperature = "";
      state.freeEntryField = "";
      state.registDate = "";
      state.isModify = false;
    },
  },
  actions: {},
};
