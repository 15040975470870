export default {
  namespaced: true,
  state: {
    yourStatus: "",
    familyStatus: "",
    currentWhereabouts: "",
    availableGoworkTime: "",
    availableTransportation: "",
    houseStatus: "",
    // NOTE GPSに関してはstoreを使わない
    freeEntryfield: "",
  },
  getters: {
    yourStatus: (state) => state.yourStatus,
    familyStatus: (state) => state.familyStatus,
    currentWhereabouts: (state) => state.currentWhereabouts,
    availableGoworkTime: (state) => state.availableGoworkTime,
    availableTransportation: (state) => state.availableTransportation,
    houseStatus: (state) => state.houseStatus,
    freeEntryfield: (state) => state.freeEntryfield,
  },
  mutations: {
    set: (state, answerItem) => {
      console.log(answerItem);
      state.yourStatus = answerItem.yourStatus;
      state.familyStatus = answerItem.familyStatus;
      state.currentWhereabouts = answerItem.currentWhereabouts;
      state.availableGoworkTime = answerItem.availableGoworkTime;
      state.availableTransportation = answerItem.availableTransportation;
      state.houseStatus = answerItem.houseStatus;
      state.freeEntryfield = answerItem.freeEntryfield;
    },
    reset: (state) => {
      state.yourStatus = "";
      state.familyStatus = "";
      state.currentWhereabouts = "";
      state.availableGoworkTime = "";
      state.availableTransportation = "";
      state.houseStatus = "";
      state.freeEntryfield = "";
    },
  },
  actions: {},
};
