function zeroPadding(number, digits = 2) {
  // digits >= 1
  // number >= 0
  // digits >= String(number).length
  const padding = "0".repeat(digits);
  return padding.concat(number).slice(-1 * digits);
}

function formatDate(date) {
  const format = "YYYY/MM/dd";
  return format
    .replace(/YYYY/, date.getFullYear())
    .replace(/MM/, zeroPadding(date.getMonth() + 1, 2))
    .replace(/dd/, zeroPadding(date.getDate(), 2));
}
function formatDatetime(date) {
  const format = "YYYY/MM/dd hh:mm:ss";
  return format
    .replace(/YYYY/, date.getFullYear())
    .replace(/MM/, zeroPadding(date.getMonth() + 1, 2))
    .replace(/dd/, zeroPadding(date.getDate(), 2))
    .replace(/hh/, zeroPadding(date.getHours(), 2))
    .replace(/mm/, zeroPadding(date.getMinutes(), 2))
    .replace(/ss/, zeroPadding(date.getSeconds(), 2));
}

export { zeroPadding, formatDate, formatDatetime };
