import Vue from "vue";
import VueRouter from "vue-router";
import cognito from "@/cognito";
import UserTop from "../views/UserTop.vue";
import SafetyAnswer from "../views/SafetyAnswer.vue";
import HealthAnswer from "../views/HealthAnswer.vue";
import SignIn from "../views/SignIn.vue";
import SignUp from "../views/SignUp.vue";
import PasswordReset from "../views/PasswordReset.vue";

Vue.use(VueRouter);

const requireAuth = async (to, from, next) => {
  if (await cognito.isAuthenticated()) {
    next();
    return;
  }

  next({
    path: "/SignIn",
    query: { redirect: to.fullPath },
  });
};

const alreadyAuthCheck = async (to, from, next) => {
  if (await cognito.isAuthenticated()) {
    next({ path: "/" });
    return;
  }

  next();
};

const routes = [
  {
    path: "/",
    name: "UserTop",
    component: UserTop,
    beforeEnter: requireAuth,
  },
  {
    path: "/SafetyAnswer/:corporateNumber/:eventName",
    name: "SafetyAnswer",
    component: SafetyAnswer,
    meta: {
      header: "none",
    },
  },
  {
    path: "/HealthAnswer/:corporateNumber/",
    name: "HealthAnswer",
    component: HealthAnswer,
    meta: {
      header: "none",
    },
  },
  {
    path: "/SignIn",
    name: "SignIn",
    component: SignIn,
    beforeEnter: alreadyAuthCheck,
  },
  {
    path: "/SignUp",
    name: "SignUp",
    component: SignUp,
    beforeEnter: alreadyAuthCheck,
  },
  {
    path: "/PasswordReset",
    name: "PasswordReset",
    component: PasswordReset,
    // NOTE: サインイン済みの状態でもパスワードリセットしたいかも？
    // 一旦ナビゲーションガードは無効にしておく
    // beforeEnter: alreadyAuthCheck,
  },
  {
    path: "/*",
    // name: "NotFound",
    // component: Notfound,
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
