import { API } from "aws-amplify";
const apiName = "protfupe";

// NOTE: 社員の特定に必要な法人番号と電話番号はLambdaがトークンから取り出すため、
// リクエストボディ等から渡すことはしない
async function getEmployee() {
  const result = {
    hasError: false,
    message: "",
    item: {},
  };

  const resourcePath = `/employee/`;
  const myInit = {
    response: true,
  };

  const apiResponse = await API.get(apiName, resourcePath, myInit).catch(
    (e) => ({
      error: e,
    })
  );
  if (apiResponse.error) {
    result.hasError = true;
    result.message = "ユーザー情報の取得に失敗しました";
    return result;
  }

  result.item = apiResponse.data.Item;
  return result;
}

export { getEmployee };
