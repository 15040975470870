<template>
  <div>
    <v-container fluid v-if="!isEmployeeLoading">
      <h1>{{ employeeNo }}: {{ employeeName }}様の最新の回答状況</h1>
      <h2>■安否確認</h2>
      <v-row align="center">
        <v-col cols="12" sm="6">
          <v-select
            v-model="selectedEventIndex"
            :items="eventSelectItems"
            item-text="text"
            item-value="value"
            single-line
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6">
          <h3 v-if="!safetyAnswerLoading">
            {{
              selectedEvent.lastUpdateDate
                ? selectedEvent.lastUpdateDate
                : "未回答"
            }}
          </h3>
        </v-col>
      </v-row>
      <v-data-table
        :headers="safetyAnswerHeaders"
        :items="tableRowItems"
        :items-per-page="-1"
        hide-default-footer
        v-if="!safetyAnswerLoading"
        class="elevation-1 mt-4 answer-table"
        mobile-breakpoint="240"
      >
        <template v-slot:[`item.value`]="{ item }">
          <span :class="cellClass(item)">{{ item.value }}</span>
        </template>
      </v-data-table>
      <v-row class="no-gutters mb-4">
        <v-btn color="primary" class="ml-auto" @click="goSafetyAnswer"
          >編集</v-btn
        >
      </v-row>
      <hr />
      <h2>■体温報告</h2>
      <v-btn color="primary" @click="goHealthAnswer"> 回答・修正をする </v-btn>
      <p class="text-caption">※当日分以外の回答・修正はできません。</p>
      <v-row align="center">
        <v-col cols="6">
          <v-select
            v-model="selectedDateIndex"
            :items="dateSelectItems"
            item-text="text"
            item-value="value"
            single-line
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="healthAnswerHeaders"
        :items="filteredHealthAnswerItem"
        :items-per-page="-1"
        hide-default-footer
        v-if="!healthAnswerLoading && filteredHealthAnswerItem.length > 0"
        class="elevation-1 mt-4 answer-table"
        mobile-breakpoint="240"
      >
        <template v-slot:[`item.bodyTemperature`]="{ item }">
          <span :class="getStyleBodyTemperature(item.bodyTemperature)">
            {{ item.bodyTemperature }}
          </span>
        </template>
      </v-data-table>
      <p v-if="!healthAnswerLoading && filteredHealthAnswerItem.length === 0">
        直近10日以内の回答データが存在しませんでした。
      </p>
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { getEmployee } from "../apis/employees";
import { getSafetyConfirmations } from "../apis/safetyConfirmations";
import { getHealthReports } from "../apis/healthReports";
import { formatDate } from "../utils/format";

export default {
  name: "UserTop",
  data: () => ({
    isEmployeeLoading: true,

    answerItems: [],
    eventSelectItems: [],
    selectedEventIndex: 1,
    healthAnswerItems: [],
    dateSelectItems: [],
    selectedDateIndex: 1,

    // TODO: storeで保持
    employeeName: "",
    employeeNo: "",
    cellphoneNumber: "",
    corporateNumber: "",

    safetyAnswerHeaders: [
      {
        text: "回答項目",
        value: "text",
        sortable: false,
        class: "table-header",
        width: "50%",
      },
      {
        text: "回答内容",
        value: "value",
        sortable: false,
        class: "table-header",
        width: "50%",
      },
    ],

    healthAnswerHeaders: [
      { text: "体温", value: "bodyTemperature" },
      { text: "自由記入欄", value: "freeEntryField" },
    ],
    safetyAnswerLoading: true,
    healthAnswerLoading: true,
  }),
  methods: {
    ...mapMutations(["setCellphoneNumber"]),
    ...mapMutations({
      setSafetyAnswerInput: "safetyAnswerInput/set",
      setHealthAnswerInput: "healthAnswerInput/set",
    }),
    // TODO: storeのactionとして実装
    async getEmployee() {
      const result = await getEmployee();
      if (result.hasError) {
        alert(result.message);
        this.isEmployeeLoading = false;
        this.getSafetyConfirmationDataFailed = true;
        this.safetyAnswerLoading = false;
        return;
      }

      const item = result.item;
      this.corporateNumber = item.corporateNumber;
      this.cellphoneNumber = item.cellphoneNumber;
      this.employeeName = item.name;
      this.employeeNo = item.employeeNo;
      this.isEmployeeLoading = false;

      return;
    },
    async getSafetyAnswers() {
      const result = await getSafetyConfirmations();
      if (result.hasError) {
        this.getSafetyConfirmationDataFailed = true;
        this.safetyAnswerLoading = false;
        return;
      }
      this.answerItems = result.items;
      this.eventSelectItems = result.items.map((item, index) => ({
        text: item.eventName,
        value: index + 1,
      }));
      this.safetyAnswerLoading = false;
    },
    async getHealthAnswers() {
      const result = await getHealthReports();
      if (result.hasError) {
        this.getHealthReportsDataFailed = true;
        this.healthLoading = false;
        return;
      }
      this.healthAnswerItems = result.items;
      this.dateSelectItems = result.items.map((item, index) => ({
        text: item.registDate,
        value: index + 1,
      }));
      this.healthAnswerLoading = false;
    },

    goSafetyAnswer() {
      this.setCellphoneNumber(this.cellphoneNumber);
      this.setSafetyAnswerInput(this.selectedEvent);
      this.$router.push(
        `/SafetyAnswer/${this.corporateNumber}/${this.encodedEventName}`
      );
    },

    goHealthAnswer() {
      this.setCellphoneNumber(this.cellphoneNumber);
      const currentDate = formatDate(new Date());
      const todayIndex = this.healthAnswerItems.findIndex(
        (item) => item.registDate === currentDate
      );
      if (todayIndex >= 0) {
        this.setHealthAnswerInput(this.healthAnswerItems[todayIndex]);
      }
      this.$router.push(`/HealthAnswer/${this.corporateNumber}/`);
    },

    hasLatLang(gpsInformation) {
      if (
        typeof gpsInformation === "object" &&
        typeof gpsInformation.latitude === "number" &&
        typeof gpsInformation.longitude === "number"
      ) {
        return true;
      }
      return false;
    },

    cellClass(item) {
      switch (item.text) {
        case "本人の状況":
          return this.getStyleYourStatus(item.value);
        case "家族の状況":
          return this.getStyleFamilyStatus(item.value);
        case "自宅の状況":
          return this.getStyleHouseStatus(item.value);
        case "出社可能時間":
          return this.getStyleAvailableGoworkTime(item.value);
      }
      return;
    },
    getStyleYourStatus(yourStatus) {
      switch (yourStatus) {
        case "重症":
          return "cell-dangerous";

        case "軽症":
          return "cell-caution";

        default:
          return "";
      }
    },
    getStyleFamilyStatus(familyStatus) {
      switch (familyStatus) {
        case "重症":
          return "cell-dangerous";

        case "軽症":
          return "cell-caution";

        default:
          return "";
      }
    },
    getStyleHouseStatus(houseStatus) {
      switch (houseStatus) {
        case "住めない":
          return "cell-dangerous";

        default:
          return "";
      }
    },
    getStyleAvailableGoworkTime(availableGoworkTime) {
      switch (availableGoworkTime) {
        case "本日は出社不可":
          return "cell-dangerous";

        case "2時間以上":
          return "cell-caution";

        default:
          return "";
      }
    },
    getStyleBodyTemperature(bodyTemperature) {
      return bodyTemperature >= 37.5 ? "cell-dangerous" : "";
    },
  },
  computed: {
    selectedEvent() {
      return this.answerItems[this.selectedEventIndex - 1];
    },
    encodedEventName() {
      return encodeURI(this.selectedEvent.eventName);
    },
    selectedHealthReport() {
      return this.healthAnswerItems[this.selectedDateIndex - 1];
    },
    // NOTE: v-data-tableのitemsに渡すため配列にする
    filteredHealthAnswerItem() {
      return [this.selectedHealthReport];
    },
    tableRowItems() {
      return [
        {
          text: "本人の状況",
          value: this.selectedEvent.yourStatus,
        },
        {
          text: "家族の状況",
          value: this.selectedEvent.familyStatus,
        },
        {
          text: "現在の居場所",
          value: this.selectedEvent.currentWhereabouts,
        },
        {
          text: "出社可能時間",
          value: this.selectedEvent.availableGoworkTime,
        },
        {
          text: "利用可能な交通手段",
          value: this.selectedEvent.availableTransportation,
        },
        {
          text: "自宅の状況",
          value: this.selectedEvent.houseStatus,
        },
        {
          text: "GPS情報",
          value: this.hasLatLang(this.selectedEvent.gpsInformation)
            ? `${this.selectedEvent.gpsInformation.latitude} , ${this.selectedEvent.gpsInformation.longitude}`
            : "-",
        },
        {
          text: "自由記入欄",
          value: this.selectedEvent.freeEntryfield,
        },
      ];
    },
  },
  beforeMount() {
    // TODO: 安否確認システムではなく社名に
    document.title = "TOP | 安否確認システム ";
  },
  async created() {
    await this.getEmployee();
    await this.getSafetyAnswers();
    await this.getHealthAnswers();
  },
};
</script>

<style scoped>
.answer-table >>> .table-header {
  background-color: whitesmoke;
}
.answer-table >>> .cell-dangerous {
  color: red;
  font-weight: bold;
}
.answer-table >>> .cell-caution {
  color: #fec718;
  font-weight: bold;
}
</style>
